.page-not-found {
  margin: 0 auto;
  text-align: center;
  max-width: 768px;
  padding: 0px 15px 50px 15px;
}

h1 {
  font-family: "Duplex Sans Web Bold";
  font-size: 62px;
  line-height: 1.2em;
  padding: 0 0 20px 0;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #eee;
  color: #eee;
}

h3 {
  font-family: "Duplex Sans Web Bold";
  font-size: 27px;
  line-height: 1.2em;
  margin: 3px 0 5px 0;
}

p {
  font-family: "pt_sansregular";
  margin: 0 0 10px 0;
  line-height: 1.6em;
}

a {
  color: #008cba;
  text-decoration: none;
}

.container {
  padding: 50px 0;
}

