@use './resources.scss';
@use './normalize.scss';
@use './stylesheet.scss';
@use './fonts.scss';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
