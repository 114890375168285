/**
 * FONTS
 */
 @font-face {
  font-family: "pt_sansregular";
  src: url(../fonts/PTS55F-webfont.eot);
  src: url(../fonts/PTS55F-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/PTS55F-webfont.woff) format("woff"), url(../fonts/PTS55F-webfont.ttf) format("truetype"), url(../fonts/PTS55F-webfont.svg#pt_sansregular) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Duplex Sans Web Bold";
  src: url(../fonts/Duplex_Sans_Web-Bold.eot);
  src: url(../fonts/Duplex_Sans_Web-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/Duplex_Sans_Web-Bold.woff) format("woff"), url(../fonts/Duplex_Sans_Web-Bold.ttf) format("truetype"), url(../fonts/Duplex_Sans_Web-Bold.svg#Duplex_Sans_Web) format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
