// Default for all iframe-embeds
iframe,
object {
  width: 100%;
  display: block;
}

// Special case for resources with iframe and adjacent elements
.resource-container {
  max-width: 100vw;
}

// Special case for auto ratio objects
.ratio-auto {
  max-width: var(--resource-max-width, initial);

  .resource-container {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: var(--resource-aspect-ratio-padding, 0);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.resource-ifragasatt {
  .resource-container {
    min-height: 700px !important;
    height: auto;

    .iframe--ifragasatt__resized {
      min-height: 400px;
      height: 100%;
      margin: auto;
      width: 100%;
    }
  }
}

.resource-flowplayer {
  .is-starting flowplayer-middle:focus flowplayer-play-icon:not(:active) {
    outline: 2px solid currentColor;
  }
  
  .is-small {
    .fp-play, .fp-pause {
      transform: scale(1.5);
    }
  }
  .is-tiny {
    .fp-play, .fp-pause {
      transform: scale(2.3);
    }
  }

  .is-paused .fp-footer {
    display: block;
  }

  .fp-rewind,
  .fp-fast-forward {
    height: 1.6em;
  }

  .flowplayer.is-tiny {
    font-size: 14px;
  }
}

.resource-googlemapsimage {
  figure {
    margin: 0;
  }

  img {
    width: 100%;
  }
}
